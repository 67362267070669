<template>
    <div class="gride" :class="['index_' + index]">
        <div class="img"></div>
        <div class="content">
            <div class="name">{{ info.title }}</div>
            <div class="text2">{{ info.unit }}</div>

            <div class="bottom">
                <el-link type="warning" underline>查看服务内容</el-link>
                <el-button type="warning" size="small" @click=""
                    >立即兑换</el-button
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'pointProduct',
    props: {
        info: {}
    },
    data() {
        return {
            index: 1
        };
    },
    mounted() {
        this.index = Math.floor(Math.random() * 6) + 1;
    }
};
</script>

<style lang="scss" scoped>
$colors: [#B38F8F, #b38fad, #8f8fb3, #a28fb3, #b3a78f, #8FA9B3];

.img {
    height: 0;
    padding-top: 100%;
}

.gride {
    &:hover {
        box-shadow: 0px 8px 17px 0px rgba(255, 165, 38, 0.12);
    }
}

.content {
    background-color: #fff;
    padding: 14px;

    .name {
        font-size: 14px;
        color: #292c33;
        line-height: 20px;
    }

    .text2 {
        font-size: 12px;
        color: #878d99;
        line-height: 17px;
        margin-top: 6px;
    }

    .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;

        .el-link {
            font-size: 12px;
        }
    }
}

@each $color in $colors {
    $index: index($colors, $color);

    .index_#{$index} {
        .img {
            background-color: $color;
        }
    }
}
</style>
