<template>
    <div class="point">
        <div
            class="top bg"
            :style="{
                backgroundImage: 'url(' + require('../../assets/img_jifen2x.jpg') + ')'
            }"
        >
            <div class="text1">{{ $t('wo-de-ji-fen') }}</div>
            <div class="top-main">
                <div class="val">{{ integral }}</div>

                <el-button type="warning" plain size="small">{{ $t('cha-kan-ming-xi') }}</el-button>

                <el-link>{{ $t('cha-kan-ji-fen-huo-qu-gui-ze-kuai-su-ji-lei-ji-fen') }}</el-link>
            </div>
        </div>

        <div class="point-title">
            {{ $t('ji-fen-shang-cheng') }}
        </div>

        <div class="main">
            <el-row :gutter="30" type="flex">
                <el-col v-for="item in list" :key="item.id">
                    <point-gride :info="item"></point-gride>
                </el-col>
            </el-row>
        </div>

        <div class="point-title">
            {{ $t('ji-fen-huo-qu-gui-ze') }}
        </div>

        <el-table header-row-class-name="warningHeader" stripe :data="tables" style="width: 100%" size="mini">
            <el-table-column :label="$t('dong-zuo-ming-cheng')" :formatter="nameFormat" min-width="100px" prop="name">
            </el-table-column>
            <el-table-column :label="$t('huo-de-ji-fen-shu-liang')" prop="points" min-width="80px"> </el-table-column>
            <el-table-column :label="$t('zhou-qi')" :formatter="cycleFormat" prop="cycle" min-width="80px">
            </el-table-column>
            <el-table-column
                :label="$t('zhou-qi-nei-zui-duo-huo-de-shu-liang')"
                prop="maxPoints"
                :formatter="nullFormat"
                min-width="100px"
            >
                <template></template>
            </el-table-column>
            <el-table-column :label="$t('zhuang-tai')" min-width="80px" prop="isComplete" align="center">
                <template slot-scope="scope">
                    <el-tag type="info" v-if="scope.row.isComplete" size="mini" effect="dark">{{
                        $t('yi-wan-cheng')
                    }}</el-tag>
                    <el-tag type="warning" v-else size="mini" effect="dark">{{ $t('wei-wan-cheng') }}</el-tag>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import PointGride from '../../components/point/Gride';
import { earnCycle } from '../../utils/AppState';
export default {
    name: 'point',
    data() {
        return {
            list: [],
            tables: []
        };
    },
    computed: {
        ...mapState(['userInfo']),
        integral() {
            return this.userInfo.integral || 0;
        }
    },
    mounted() {
        this.$http.post('/exchangePoints/all', {}, { body: 'json' }).then(res => {
            this.list = res.content;
        });

        this.$http.post('/earnPoints/show').then(res => {
            this.tables = res;
        });
    },
    components: {
        PointGride
    },
    methods: {
        cycleFormat(row, column, cellValue, index) {
            return this.$t(earnCycle.get(cellValue));
        },
        nameFormat(row, column, cellValue, index) {
            return this.getName(row, ['name', 'enName']);
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/pagebleTable';
.point {
    background-color: transparent !important;
    overflow: hidden;
}

.top {
    height: 160px;
    // background: linear-gradient(270deg, rgba(255, 165, 38, 0) 0%, #ffa526 100%);
    padding: 0 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .text1 {
        font-size: 14px;
        color: #ffffff;
        line-height: 20px;
    }

    .top-main {
        display: flex;
        align-items: center;
        margin-top: 10px;
        .val {
            font-size: 48px;
            font-family: OSP-DIN, OSP;
            font-weight: normal;
            color: #ffffff;
            line-height: 55px;
        }

        .el-button {
            margin-left: 51px;
            background-color: #ffffff;

            &:hover {
                background-color: #ffdba8;
                color: #ffa526;
            }
        }

        .el-link {
            font-size: 14px;
            color: #ffffff;
            line-height: 20px;
            margin-left: 36px;
            margin-bottom: 10px;
            align-self: flex-end;

            &:hover {
                &::after {
                    background-color: #fff;
                    border-color: #fff;
                }
            }
        }
    }
}

.point-title {
    font-size: 16px;
    font-weight: bold;
    color: #878d99;
    line-height: 22px;
    padding: 60px 0 20px;
}
.main {
    overflow: hidden;
}
</style>

<style lang="scss">
.warningHeader {
    th {
        background-color: #ffa526;
        color: #fff;
        font-weight: normal;
    }
}
</style>
